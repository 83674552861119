/* eslint-disable sort-keys */
import { apiFetch } from '@api/base';
import type { TAction, TDispatch } from '@models/types';

import { PesananActionType } from './types';

import type { DetailPesananDataModel, KuantitasPesananDataModel, PembayaranBookingFeeDanUangMukaDataModel, PerhitunganHargaDataModel, PesananAction, PesananModel } from './types';

const DUMMY_DETAIL_PESANAN: DetailPesananDataModel[] = [
  {
    no_pesanan: 'AABB1234',
    pax: '1',
    route: 'CGK - JED (Pulang Pergi)',
    departure_date: '2023-12-01',
    pnr: 'H1231(HK)',
    flight_id: '1'
  },
  {
    no_pesanan: 'AABB1235',
    pax: '2',
    route: 'CGK - JED (Pulang Pergi)',
    departure_date: '2023-12-02',
    pnr: 'H1231(HK)',
    flight_id: '2'
  }
];

const DUMMY_PERHITUNGAN_HARGA: PerhitunganHargaDataModel[] = [
  {
    uraian: 'Tiket HRT89U SV817/SV816',
    pax: 32,
    nominal: 13000000,
    total: 416000000,
    keterangan: 'CGK-JED-CGK'
  },
  {
    uraian: 'Denda Pengurangan Pesanan',
    pax: 4,
    nominal: 150000,
    total: 600000,
    keterangan: ''
  }
];

const DUMMY_PEMBAYARAN_BOOKING_FEE_DAN_UANG_MUKA: PembayaranBookingFeeDanUangMukaDataModel[] = [
  {
    uraian: 'Booking Fee (ditagihkan 29/03/2019)',
    pax: 36,
    nominal: 150000,
    total: 5400000,
    keterangan: 'Sudah dibayar 29/03/2029'
  }
];

const DUMMY_KUANTITAS_PESANAN: KuantitasPesananDataModel[] = [
  {
    noPesanan: 'A35FF246',
    tanggalPesan: '23-05-2019',
    qty: 36,
    pnr: 'HRT89U',
    statusPnr: 'HK'
  }
];

const PesananDefault: PesananModel = {
  main: {
    data: [],
    total: 0
  },
  flight: {
    departing: [{
      aircraft: '',
      arrival: '',
      arrivalPoint: '',
      arrivalTime: '',
      departure: '',
      departurePoint: '',
      departureTime: '',
      distance: 0,
      flightId: '',
      flightNumber: '',
      flightTimeHours: 0
    }],
    number: '',
    paymentMethod: '',
    pnr: '',
    pax: 0,
    flight_number: '',
    returning: [{
      aircraft: '',
      arrival: '',
      arrivalPoint: '',
      arrivalTime: '',
      departure: '',
      departurePoint: '',
      departureTime: '',
      distance: 0,
      flightId: '',
      flightNumber: '',
      flightTimeHours: 0
    }]
  },
  tac: {
    data: {
      booking_fee: [],
      down_payment: [],
      full_payment: [],
      full_payment_approval: [],
      insert_passenger: [],
      issued_ticket: [],
      materialisasi: [],
      order_reduction: [],
      others: [],
      ticket_terms: [],
      time_limit: []
    }
  },
  paymentGate: {
    data: {
      ATM: [],
      Mobile_Banking: []
    }
  },
  paymentDetail: {
    for_table_1: [],
    for_table_2: [],
    for_table_3: []
  },
  //
  tablePesanan: [],
  detailPenerbangan: [],
  rincianHarga: [],
  pelunasan: [],
  perhitunganPelunasan: [],
  petunjukPembayaran: [],
  kuantitasPesanan: [],
  perhitunganHarga: [],
  pembayaranBookingFeeDanUagMuka: [],
  summaryPaymentInstruction: {
    account_name: '',
    account_number: '',
    bank_name: '',
    due_date: '',
    order_number: '',
    total: 0,
    payment_type: '',
    inventory_type: '',
    can_reduce_pax: false
  }
};

const PesananReducer = (state: PesananModel = PesananDefault, action: PesananAction): PesananModel => {
  switch (action.type) {
    case PesananActionType.GetAllRequesterOrder:
      return { ...state, main: action.data };
    case PesananActionType.GetFlightDetail:
      return { ...state, flight: action.data };
    case PesananActionType.GetTacDetail:
      return { ...state, tac: action.data };
    case PesananActionType.GetPayementGateDetail:
      return { ...state, paymentGate: action.data };
    case PesananActionType.GetPaymentDetail:
      return { ...state, paymentDetail: action.data };
    //
    case PesananActionType.GetDetailPenerbangan:
      return { ...state, detailPenerbangan: action.data };
    case PesananActionType.GetPetunjukPembayaran:
      return { ...state, petunjukPembayaran: action.data };
    case PesananActionType.GetKuantitasPesanan:
      return { ...state, kuantitasPesanan: action.data };
    case PesananActionType.GetPerhitunganHarga:
      return { ...state, perhitunganHarga: action.data };
    case PesananActionType.GetPembayaranBookingFeeDanUangMuka:
      return { ...state, pembayaranBookingFeeDanUagMuka: action.data };
    case PesananActionType.GetSummaryPaymentInstruction:
      return { ...state, summaryPaymentInstruction: action.data };

    default:
      return { ...state };
  }
};

const PesananCommand = {
  // NEW
  GetAllRequesterOrder: (payload: unknown, token: string): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return apiFetch(token).post(`/order/1.0/agent/orders-grid`, payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data,
              type: PesananActionType.GetAllRequesterOrder
            });
          }
        });
    };
  },
  GetFlightDetail: (id: string, token: string): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return apiFetch(token).get(`/order/1.0/agent/get-flight-detail?number=${id}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data.data,
              type: PesananActionType.GetFlightDetail
            });
          }
        });
    };
  },
  GetTacDetail: (payload: string, token: string): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return apiFetch(token).post(
        `/order/1.0/agent/generate-tac-by-order-number`,
        {
          number: payload
        }
      )
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data,
              type: PesananActionType.GetTacDetail
            });
          }
        });
    };
  },
  GetPayementGateDetail: (id: string, token: string): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return apiFetch(token).get(`/order/1.0/payment-instruction/${id}`)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data,
              type: PesananActionType.GetPayementGateDetail
            });
          }
        });
    };
  },
  GetPaymentDetail: (payload: string, token: string): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return apiFetch(token).post(`/order/1.0/agent/order-number/payment-detail`, {
        number: payload
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data.data,
              type: PesananActionType.GetPaymentDetail
            });
          }
        });
    };
  },
  getSummaryPaymentInstruction: (payload: string, token: string): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return apiFetch(token).post(`/order/1.0/agent/summary-payment-instruction`, {
        number: payload
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data.data,
              type: PesananActionType.GetSummaryPaymentInstruction
            });

            return response.data.error;
          }
        });
    };
  },
  // OLD
  getDetailPenerbangan: (): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_DETAIL_PESANAN,
          type: PesananActionType.GetDetailPenerbangan
        });
      });
    };
  },
  getKuantitasPesanan: (): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_KUANTITAS_PESANAN,
          type: PesananActionType.GetKuantitasPesanan
        });
      });
    };
  },
  getPerhitunganHarga: (_payload: unknown): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_PERHITUNGAN_HARGA,
          type: PesananActionType.GetPerhitunganHarga
        });
      });
    };
  },
  getPembayaranBookingFeeDanUangMuka: (): TAction<PesananAction, void> => {
    return (dispatch: TDispatch<PesananAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_PEMBAYARAN_BOOKING_FEE_DAN_UANG_MUKA,
          type: PesananActionType.GetPembayaranBookingFeeDanUangMuka
        });
      });
    };
  }
};

export { PesananCommand, PesananReducer, PesananDefault };
